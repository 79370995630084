<template>
  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>批量课时申请 - 待审核</b>
        </el-col>

        <el-col :span="2" :push="1" > 申报类型:</el-col>
        <el-col :span="3" :pull="0">

          <el-select v-model="aType" size="small"  placeholder="请选择">
            <el-option
              v-for="item in application_type_options()"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2"></el-col>
        <el-col :span="2" :push="1" > 律所名称: </el-col>
        <el-col :span="4" >
          <el-input
            placeholder="请输入"
            v-model="searchFirmName"
            clearable
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="3" :pull="0">

          <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        :header-cell-style="{ background: '#eee', color: '#333' }"
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange">
<!--        <el-table-column-->
<!--          type="selection"-->
<!--          width="55">-->
<!--        </el-table-column>-->
        <el-table-column prop="createTime" label="申报日期" align="center" width="140px"></el-table-column>
        <el-table-column prop="dname" label="申报律所" align="center" width="200px"></el-table-column>
        <el-table-column prop="type" label="申报类型" align="center" width="140x"></el-table-column>
        <el-table-column prop="detail" label="具体类别" align="center"></el-table-column>
<!--        <el-table-column prop="content" label="活动内容" align="center"></el-table-column>-->
        <el-table-column prop="score" label="申报学时/时长"  width="110x" align="center"></el-table-column>
        <el-table-column prop="num" label="申报人数" width="80x" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="300px" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-s-custom"
                @click="handleLawyerList(scope.row)">
                律师名单
              </el-button>
              <el-button
                size="mini"
                type="warning"

                icon="el-icon-picture-outline"
                @click="handlePhoto(scope.row)">
                查看材料
              </el-button>
            </div>
            <div style="margin-top: 10px">
              <el-button
                size="mini"
                type="success"
                icon="el-icon-finished"
                @click="handlePass(scope.row)">
                通过申报
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-document-delete"
                @click="handleRefuse(scope.$index, scope.row)">
                驳回申报
              </el-button>

            </div>

          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>


    </div>


    <el-dialog
      title="通过审核"
      :visible.sync="passDialogVisible"
      width="30%"
      class="passDialog"
    >
      <h1>同意通过吗</h1>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="passDialogVisible = false">取消</el-button>
            <el-button size="small" type="primary" @click="handlePassConfirm">确 定</el-button>
          </span>
    </el-dialog>


    <el-dialog
      title="驳回"
      :visible.sync="refuseDialogVisible"
      width="60%"
      class="refuseDialog"
    >
      <div>

        <h3>请填写驳回理由</h3>
        <el-divider></el-divider>

        <div style="margin: 20px 0;"></div>
        <el-input
          placeholder="请输入内容"
          v-model="refuseInfo"
          type="textarea"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="refuseDialogVisible = false, refuseInfo = ''">取消</el-button>
            <el-button size="small" type="primary" @click="handleRefuseConfirm">确 定</el-button>
          </span>
    </el-dialog>

<!--    <el-dialog-->
<!--      title="批量审核"-->
<!--      :visible.sync="passSelectedDialogVisible"-->


<!--      width="30%"-->
<!--      class="passSeleedDialog"-->
<!--    >-->
<!--      <h1>同意所选申报通过吗</h1>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--            <el-button @click="passSelectedDialogVisible = false">取消</el-button>-->
<!--            <el-button type="primary" @click="handlePassSelectedConfirm">确 定</el-button>-->
<!--          </span>-->
<!--    </el-dialog>-->

    <el-dialog
      title="证明材料"
      :visible.sync="photoDialogVisible"

      width="70%"
      class="photoDialog"
    >
      <h2>具体类别</h2>
      <span>{{currentData.detail}}</span>
      <h2>活动内容</h2>
      <span>{{currentData.content}}</span>
      <el-row>

        <h2>图片</h2>
        <div v-for="item in url">
          <el-image
            style="width: 100px; height: 100px; margin-right: 10px; float: left"
            :src="item"
            :preview-src-list="url">
          </el-image>
        </div>
      </el-row>
    </el-dialog>

    <!--    律师名单-->
    <el-dialog
      :visible.sync="lawyerListDialogVisible"

      width="30%"
      class="lawyerDialog"
    >

      <el-table
        v-loading="lawyer_loading"
        element-loading-text="加载中"
        max-height="400"
        ref="multipleTable"
        :data="lawyerList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="律师证号"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>
<!--      <span slot="footer" class="dialog-footer">-->
<!--&lt;!&ndash;            <el-button @click="handleLawyerCancel">取消选择</el-button>&ndash;&gt;-->
<!--            <el-button type="primary" @click="lawyerListDialogVisible=false">确定</el-button>-->
<!--          </span>-->
    </el-dialog>

  </div>
</template>

<script>
  import  {formatDate} from '@/utils/date'
  import { request } from '@/network/network'
  import {application_status_dict, application_status_options,application_type_dict, application_type_options,
    application_detailPublic_dict, application_detailPublic_options, application_detailTrain_dict, application_detailTrain_options
  } from '@/utils/dict'
  import {train_status_dict, train_consumer_dict,train_status_options, train_consumer_options } from '@/utils/dict'
  export default {
    data() {
      return {
        loading: true,
        lawyer_loading: true,
        total: 0,
        currentPage: 0,
        searchFirmName: '',
        searchMap: '',
        multipleSelection: [],
        passDialogVisible: false,
        refuseDialogVisible: false,
        passSelectedDialogVisible: false,
        photoDialogVisible:false,
        lawyerListDialogVisible:false,
        aType:'',

        url:[],

        lawyerList:[],
        currentData: {},
        tableData: [],
        auditBatchId: '',
        refuseInfo: '',
        adminInformation:''
      };
    },
    methods: {
      application_type_options() {
        return application_type_options
      },
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getBatchs(this.searchMap, this.aType,val)
      },
      //搜索按钮
      handleSearch() {
        this.searchMap= this.searchFirmName
        this.getBatchs(this.searchMap, this.aType,null)
      },
      //重置搜索框
      clearInput() {
        this.searchTitle = ''
        this.searchMap = ''
        this.aType=''
          this.getBatchs()
      },


      //多选事件
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //查看照片
      handlePhoto(row){
        this.currentData = row
        this.url = []

        let photos = JSON.parse(row.photo)
        if(row.photo ==null || row.photo.trim().length === 0){
       request({
            url: '/oss/getUrl',
            method: 'get',
            params: {
              appId: row.id,
            }
          }).then(res => {
            let ossPhotos = JSON.parse(res.data);
            for (const ossPhoto of ossPhotos) {
              // let path = 'https://api.shuobocloud.cn' + photo
              this.url.push(ossPhoto)
            }
          })
        }else{
          for (const photo of photos) {
            let path = 'https://law-1257239481.cos.ap-shanghai.myqcloud.com/static' + photo
            // let path = 'https://api.shuobocloud.cn' + photo
            this.url.push(path)
          }
        }
        this.photoDialogVisible=true
      },
      // 查看律师名单
      handleLawyerList(row){
        this.lawyer_loading = true
        this.getBatchLawyers(row.bid)
        this.lawyerListDialogVisible = true;

      },

      // 通过审核
      handlePass(row) {
        this.application = row
        this.passDialogVisible = true
        this.auditBatchId = row.bid

      },
      handlePassConfirm() {
        let thisBatch = ''
        this.getBatchById(this.auditBatchId)
          .then(res => {
            thisBatch = res.data
            if(thisBatch.updateTime == null){
              this.passBatch(this.auditBatchId)
                .then((res) => {
                  this.$message({
                    message: '审核成功！',
                    type: 'success',
                    duration: 1000
                  })
                  this.getBatchs();

                })
                .catch((err) => {
                  this.$message({
                    message: '发生错误，审核失败！',
                    type: 'error',
                    duration: 1000
                  })
                  this.getBatchs();
                })
              this.passDialogVisible = false
              this.auditBatchId = ''
            }
            else{
              this.$message({
                message: "该批量申请已被其他管理员审核！",
                type: "warning",
                duration: 2000,
              });
              this.getBatchs()
              // this.auditBatchId = ''
              // this.passDialogVisible = false
            }
          })
          .catch((err) => {
            this.getBatchs();
            this.$message({
              message: "发生错误，审核失败！",
              type: "error",
              duration: 1000,
            });
            this.passDialogVisible = false
          })
      },
      //驳回申请
      handleRefuse(index, row) {
        this.refuseDialogVisible = true
        this.auditBatchId = row.bid
      },
      handleRefuseConfirm() {
        let thisBatch = ''
        this.getBatchById(this.auditBatchId)
          .then(res => {
            thisBatch = res.data
            if(thisBatch.updateTime == null){
              this.refuseBatch( this.auditBatchId, this.refuseInfo)
                .then((res) => {
                  this.$message({
                    message: "审核成功！",
                    type: "success",
                    duration: 1000,
                  });
                  this.refuseInfo = ''
                  this.getBatchs();
                })
                .catch((err) => {
                  this.$message({
                    message: "发生错误，审核失败！",
                    type: "error",
                    duration: 1000,
                  });
                  this.getBatchs();
                });
              this.auditBatchId=''
              this.refuseDialogVisible = false
            }
            else{
              this.$message({
                message: "该批量申请已被其他管理员审核！",
                type: "warning",
                duration: 2000,
              });
              this.getBatchs()
              this.auditBatchId = ''
              // this.passDialogVisible = false
            }
          })
          .catch((err) => {
            this.getBatchs();
            this.$message({
              message: "发生错误，审核失败！",
              type: "error",
              duration: 1000,
            });
            this.passDialogVisible = false
          })
      },

      getBatchById(id) {
        return request({
          url: '/batch/batch',
          method: 'get',
          params: {
            id
          }
        })
      },

      getBatchs(dname, aType,page) {
        return request({
          url: '/batch/batchs',
          method: 'get',
          params: {
            dname,
            aType,
            page,
            status: 2,
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
            item.status = application_status_dict[item.status];
            item.type =  application_type_dict[item.type];
            // item.createTime = formatDate(item.createTime)
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            return item
          })
          this.loading = false
        })
      },
      getBatchLawyers(bid){
        return request({
          url: '/batch/batchLawyers',
          method: 'get',
          params: {
            bid
          }
        }).then(res => {
          this.lawyerList = res.data
          this.lawyer_loading = false
        })

      },
      //传bid和aid
      passBatch(bid) {
        return request({
          url: '/batch/passBatch',
          method: 'get',
          params: {
            bid,
            aid:this.adminInformation.id
          }
        })
      },

      //传bid 和info,和aid
      refuseBatch(bid, info) {
        return request({
          url: '/batch/refuseBatch',
          method: 'get',
          params: {
            bid,
            info,
            aid:this.adminInformation.id
          }
        })
      }
    },

    created() {
      this.getBatchs()
      this.adminInformation = JSON.parse(sessionStorage.getItem("information"))
    }

  }
</script>

<style scoped>
  .input-width {
    width: 80%;
  }

</style>
