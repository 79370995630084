<template>
  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>批量课时申请 - 已审核</b>
        </el-col>

        <el-col :span="2" :push="1" > 律所名称: </el-col>
        <el-col :span="4" >
          <el-input
            placeholder="请输入"
            v-model="searchFirmName"
            clearable
            size="small"
          >
          </el-input>
        </el-col>
        <el-date-picker
          type="date"
          size="small"
          v-model="valueStart"
          value-format="yyyy-MM-dd"
          placeholder="开始时间" >
        </el-date-picker>
        <el-date-picker
          type="date"
          size="small"
          v-model="valueEnd"
          value-format="yyyy-MM-dd"
          placeholder="结束时间" >
        </el-date-picker>
        <el-col :span="6" :pull="0">
          <el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>
          <el-button type="success" size="small" icon="el-icon-download"  @click="exportAll">导出汇总</el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-text="加载中"

        stripe
        :header-cell-style="{ background: '#eee', color: '#333' }"
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :default-sort = "{prop: 'updateTime', order: 'descending'}"
        @selection-change="handleSelectionChange">
<!--        <el-table-column-->
<!--          type="selection"-->
<!--          width="55">-->
<!--        </el-table-column>-->

        <el-table-column  prop="updateTime" label="审核日期" align="center" width="140px"></el-table-column>
<!--        <el-table-column prop="createTime" label="申报日期" align="center" width="140px"></el-table-column>-->
        <el-table-column prop="dname" label="申报律所" align="center" width="200px"></el-table-column>
        <el-table-column prop="type" label="申报类型" align="center" width="140px"></el-table-column>
<!--        <el-table-column prop="detail" label="具体类别" align="center"></el-table-column>-->
<!--        <el-table-column prop="content" label="活动内容" align="center"></el-table-column>-->
        <el-table-column prop="score" label="申报学时/时长" align="center" width="110px"></el-table-column>
<!--        <el-table-column prop="num" label="申报人数" align="center" width="100px"></el-table-column>-->
        <el-table-column prop="status" label="审核状态" align="center" width="110px"></el-table-column>
        <el-table-column prop="info" label="审核意见" align="center"></el-table-column>
        <el-table-column prop="aname" label="审核人" align="center" width="100px"></el-table-column>
        <el-table-column label="操作" align="center" width="120px" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="info"

                icon="el-icon-s-custom"
                @click="handleLawyerList(scope.row)">
                律师名单
              </el-button>
            </div>
           <div style="margin-top: 10px">
             <el-button

               size="mini"
               type="warning"
               icon="el-icon-picture-outline"
               @click="handlePhoto(scope.row)">
               查看材料
             </el-button>
           </div>

          </template>
        </el-table-column>
      </el-table>

      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>

    <el-dialog
      title="证明材料"
      :visible.sync="photoDialogVisible"

      width="70%"
      class="photoDialog"
    >
      <h2>具体类别</h2>
      <span>{{currentData.detail}}</span>
      <h2>活动内容</h2>
      <span>{{currentData.content}}</span>
      <el-row>

        <h2>图片</h2>
        <div v-for="item in url">
          <el-image
            style="width: 100px; height: 100px; margin-right: 10px; float: left"
            :src="item"
            :preview-src-list="url">
          </el-image>
        </div>
      </el-row>

<!--      <span slot="footer" class="dialog-footer">-->
<!--            <el-button type="primary" @click="photoDialogVisible=false">关闭</el-button>-->
<!--          </span>-->
    </el-dialog>

    <!--    律师名单-->
    <el-dialog

      title="参与改活动律师列表"
      :visible.sync="lawyerListDialogVisible"
      width="30%"
      class="lawyerDialog"
    >
      <el-table
        v-loading="lawyer_loading"
        element-loading-text="加载中"
        ref="multipleTable"
        :data="lawyerList"
        max-height="400"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="律师证号"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>
<!--      <span slot="footer" class="dialog-footer">-->
<!--&lt;!&ndash;            <el-button @click="handleLawyerCancel">取消选择</el-button>&ndash;&gt;-->
<!--            <el-button type="primary" @click="lawyerListDialogVisible=false">确定</el-button>-->
<!--          </span>-->
    </el-dialog>
    <el-dialog
      title="导出考核汇总"
      align="center"
      :visible.sync="ifExport"
      width="50%">
      <h1>共查询到{{json_data.length}}条记录</h1>

      <br>

      <download-excel
        class = "export-excel-wrapper"
        :data = "json_data"
        :fields = "json_fields"
        :name = "exportName">
        <!--          name = "律师考核汇总.xls">-->
        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
        <el-button type="success" size="small" icon="el-icon-download" @click="ifExport=false">下载excel</el-button>
      </download-excel>


    </el-dialog>
  </div>
</template>

<script>
  import { request } from '@/network/network'
  import {application_status_dict, application_status_options,application_type_dict, application_type_options,
    application_detailPublic_dict, application_detailPublic_options, application_detailTrain_dict, application_detailTrain_options
  } from '@/utils/dict'
  import {LAudited} from "@/utils/export";
  export default {
    data() {
      return {
        loading: true,
        lawyer_loading: true,
        total: 0,
        total1:0,
        currentPage: 0,
        searchFirmName: '',
        searchMap: '',
        multipleSelection: [],

        photoDialogVisible:false,
        lawyerListDialogVisible:false,

        lawyerList:[],
        currentData: [],
        tableData: [],
        tableData1: [],
        url:[],
        valueStart:'',
        valueEnd:'',
        json_fields: LAudited,
        json_data: [],
        exportName:'',
        ifExport:false,
      };
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getBatchs(this.searchMap,this.valueStart,this.valueEnd, val)
      },
      //搜索按钮
      handleSearch() {
        this.searchMap= this.searchFirmName
        this.getBatchs(this.searchMap, this.valueStart,this.valueEnd,this.currentPage)
      },
      //重置搜索框
      clearInput() {
        this.searchTitle = ''
        this.searchMap = ''
        this.valueStart=''
        this.valueEnd=''
        this.getBatchs()
      },
      exportAll(){

        this.exportName = '律师批量申报审核列表' + this.valueStart+'-'+this.valueEnd + '.xls'
        const loading = this.$loading({
          lock: true,
          text: '正在加载批量审核数据，请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getBigApplications(this.searchMap, this.valueStart,this.valueEnd, 0).then(
          res => {loading.close();
            this.ifExport = true
          })

      },

      getBigApplications( lsname,valueStart, valueEnd,page) {
        return request({
          url: '/application/bidApplications',
          method: 'get',
          params: {
            dname: lsname,
            valueStart:valueStart,
            valueEnd:valueEnd,
            page: page,
            status: 3, //传3可同时查3和4
          }
        }).then(res => {
          this.total1 = res.data.total
          let temp = res.data.list
          this.tableData1 = temp.map(item => {
            if(item.info == null || item.info == ''){item.info="-"}
            item.status = application_status_dict[item.status];
            item.type =  application_type_dict[item.type];
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            // item.createTime = formatDate(item.createTime)
            // item.updateTime = formatDate(item.updateTime)
            return item
          })
          // this.loading = false
          this.json_data = this.tableData1
        })
      },
      //多选事件
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //查看照片
      handlePhoto(row) {
        this.currentData =row
        this.url = []

        // this.url = encodeURI(row.photo);
        let photos = JSON.parse(row.photo)
        if(row.photo ==null || row.photo.trim().length === 0){
      request({
            url: '/oss/getUrl',
            method: 'get',
            params: {
              appId: row.id,
            }
          }).then(res => {
            let ossPhotos = JSON.parse(res.data);
            for (const ossPhoto of ossPhotos) {
              // let path = 'https://api.shuobocloud.cn' + photo
              this.url.push(ossPhoto)
            }
          })
        }else{
          for (const photo of photos) {
            let path = 'https://law-1257239481.cos.ap-shanghai.myqcloud.com/static' + photo
            // let path = 'https://api.shuobocloud.cn' + photo
            this.url.push(path)
          }
        }
        this.photoDialogVisible=true
      },
      // 查看律师名单
      handleLawyerList(row){
        this.lawyerList = []
        this.lawyer_loading =true
        this.getBatchLawyers(row.bid)
        this.lawyerListDialogVisible = true;
      },
      getBatchs(dname,valueStart, valueEnd,page) {
        return request({
          url: '/batch/batchs',
          method: 'get',
          params: {
            dname,
            valueStart,
            valueEnd,
            page,
            status: 3,

          }
        }).then(res => {
          this.total = res.data.total
          this.tableData = res.data.list

          let temp = res.data.list
          this.tableData = temp.map(item => {
            if(item.info == null || item.info == ''){item.info="-"}
            item.status = application_status_dict[item.status];
            item.type =  application_type_dict[item.type];
            // item.createTime = formatDate(item.createTime)
            // item.updateTime = formatDate(item.updateTime)
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            return item
          })
          this.loading = false

        })
      },
      getBatchLawyers(bid){
        return request({
          url: '/batch/batchLawyers',
          method: 'get',
          params: {
            bid
          }
        }).then(res => {
          this.lawyerList = res.data
          this.lawyer_loading = false
        })

      }
    },
    created() {
      this.getBatchs()
    }




  }
</script>

<style scoped>
  .input-width {
    width: 80%;
  }

</style>

